html,
body {
  font-size: 16px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
  "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth !important;
}

*,
*:after,
*:before {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
  monospace;
}

::-webkit-scrollbar {
  width: 7px !important;
  height: 7px;
  cursor: pointer;
}
::-webkit-scrollbar-track {
  background: #edf1ff;
}
::-webkit-scrollbar-thumb {
  background: #3d68ff;
  border-radius: 10px !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus {
  background-color: transparent;
  transition: background-color 5000s ease-in-out 0s !important;
}

.container-scroll {
  width: 100%;
  height: calc(100vh - 100px);
  padding: 20px 25px;
}

.customContainer {
  background-color: #fff;
  border-radius: 12px;
}

.sort-field {
  cursor: pointer;
  &:hover {
    color: #3d68ff;
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}
